import React from 'react';
import Img from 'gatsby-image';

import styled from '@emotion/styled';

const BenefitsCard = ({ data }) => {
  return (
    <StyledBenefitsCard className="benefits-card">
      <div className="benefits-card__photo-block">
        <img src={data.photo} alt={data.alt} className="benefits-card__photo" />
      </div>
      <div className="benefits-card__info">
        <h4 className="benefits-card__title">{data.title}</h4>
      </div>
    </StyledBenefitsCard>
  );
};

const StyledBenefitsCard = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.misc.borderRadius};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  width: 416px;
  height: 354px;
  .benefits-card {
    &__title {
      box-sizing: border-box;
      padding: 20px 57px 20px 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      margin: 0;
      color: ${props => props.theme.colors.anthracite};
    }
    &__photo-block {
      width: 100%;
      height: 269px;
    }
    &__photo {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .benefits-card {
      &__title {
        max-width: 380px;
        padding: 20px;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    box-shadow: none;
    .benefits-card {
      &__title {
        padding: 20px 25px;
        max-width: 100%;
      }
      &__photo-block {
        height: 269px;
      }
    }
  }
`;

export default BenefitsCard;
