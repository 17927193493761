import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from '@emotion/styled';
import useSitedataAssets from 'hooks/useSitedataAssets';
import SearchTabs from './SearchTabs';

const TopSection = () => {
  const { t } = useTranslation();
  const { coverImage } = useSitedataAssets();

  return (
    <>
      <link rel="preload" as="image" href={coverImage!} />
      <StyledTopSection className="top-section">
        <div className="top-section__image-wrapper">
          <img src={coverImage!} alt="home-cover-image" className="top-section__cover-image" />
        </div>
        <div className="home-info-block">
          <h1 className="home-info-block__title" data-cy="title_text">
            <Trans i18nKey="home_page.title1" />
          </h1>
          {t('home_page.caption') && (
            <h2 className="home-info-block__caption" data-cy="caption_text">
              {t('home_page.caption')}
            </h2>
          )}
          <SearchTabs />
        </div>
      </StyledTopSection>
    </>
  );
};

const StyledTopSection = styled.div`
  height: 600px;
  padding: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .top-section {
    &__image-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      filter: brightness(80%);
    }
    &__cover-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .home-info-block {
    position: relative;
    z-index: 2;
    max-width: 680px;
    flex: 1;
    h1,
    h2 {
      color: ${props => props.theme.colors.white};
    }
    &__title {
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin: 0 0 22px;
      text-align: center;
    }
    &__caption {
      margin: 0 0 16px;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 16px;
    height: 500px;
    .home-info-block {
      max-width: 100%;
      &__title {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
`;

export default TopSection;
