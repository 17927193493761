import React, { useMemo } from 'react';
import { navigate } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button } from 'components';
import useLayout from 'hooks/useLayout';
import NoSSR from 'components/NoSSR';
import BenefitsCard from './BenefitsCard';

const BenefitsSection = () => {
  const { t } = useTranslation();
  const layout = useLayout();
  const images = useStaticQuery(graphql`
    {
      card1: file(name: { eq: "family1" }, sourceInstanceName: { eq: "sitedataAssets" }) {
        childImageSharp {
          fluid(maxWidth: 416, maxHeight: 269) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      card2: file(name: { eq: "family2" }, sourceInstanceName: { eq: "sitedataAssets" }) {
        childImageSharp {
          fluid(maxWidth: 416, maxHeight: 269) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const benefitsCards = useMemo(
    () => [
      {
        id: 1,
        title: t('home_page.benefits_caption'),
        photo: images.card1.childImageSharp.fluid.src,
        alt: t('home_page.benefits_caption2')
      },
      {
        id: 2,
        title: t('home_page.benefits_caption2'),
        photo: images.card2.childImageSharp.fluid.src,
        alt: t('home_page.benefits_caption2')
      }
    ],
    []
  );

  const benefitsCardsItems = layout === 'desktop' ? benefitsCards : benefitsCards.slice(0, 1);

  return (
    <StyledBenefitsSection className="benefits-section">
      <div className="benefits-section-wrapper">
        <div className="benefits-cards">
          <NoSSR>
            {benefitsCardsItems.map(item => (
              <BenefitsCard key={item.id} data={item} />
            ))}
          </NoSSR>
        </div>
        <div className="benefits-info">
          <div className="benefits-info-wrapper">
            <h4 className="benefits-info__title">{t('home_page.title2')}</h4>
            <p className="benefits-info__description">{t('home_page.description')}</p>
            <Button className="benefits-info__button" primary onClick={() => navigate('/about-us')}>
              {t('common.learn_more')}
            </Button>
          </div>
        </div>
      </div>
    </StyledBenefitsSection>
  );
};

const StyledBenefitsSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 16px;

  .benefits-section-wrapper {
    display: grid;
    position: relative;
    max-width: 1334px;
    padding: 28px 0;
    grid-template-columns: 850px 1fr;
    flex: 1;
    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background: ${props => props.theme.colors.lightGray};
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .benefits-cards {
    display: grid;
    grid-gap: 18px;
    grid-template-columns: repeat(2, 416px);
    .benefits-card {
      width: 100%;
    }
  }
  .benefits-info {
    display: flex;
    padding: 16px 16px 16px 40px;
    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      margin: 0 0 20px;
      color: ${props => props.theme.colors.anthracite};
    }
    &__description {
      font-size: 14px;
      line-height: 22px;
      color: ${props => props.theme.colors.lightBlack};
      margin: 0 0 32px;
    }
    &__button {
      width: 140px;
    }
  }
  .benefits-info-wrapper {
    max-width: 342px;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    position: relative;
    padding: 0 16px;
    .benefits-cards {
      grid-template-columns: none;
      grid-gap: 0;
      max-width: 831px;
    }
    .benefits-section-wrapper {
      position: static;
      grid-template-columns: minmax(374px, 831px) max-content;
      &:before {
        width: calc(100% - 32px);
        left: 16px;
      }
    }
    .benefits-info-wrapper {
      max-width: 304px;
    }
    .benefits-info {
      &__title {
        max-width: 223px;
        line-height: 28px;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    position: relative;
    padding: 0 16px;
    .benefits-section-wrapper {
      position: relative;
      grid-template-columns: 1fr;
      padding: 28px 0 0;
      &:before {
        width: 100%;
        left: 0;
      }
    }
    .benefits-info-wrapper {
      padding: 0 0 43px;
      max-width: 100%;
      width: 100%;
    }
    .benefits-info {
      padding: 0;
      &__title {
        font-size: 16px;
        line-height: 24px;
        max-width: 100%;
        margin: 0 28px 20px 0;
      }
      &__button {
        width: 100%;
        height: 40px;
        font-size: 12px;
        line-height: 24px;
      }
    }
    .benefits-cards {
      grid-template-columns: 1fr;
      height: auto;
      grid-row: 2;
    }
  }
`;

export default BenefitsSection;
